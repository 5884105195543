import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import '../assets/styles/components/footer.scss';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        facebook
        instagram
        linkedin
        phone
        email
      }
      allAboutus {
        edges {
          node {
            url
            text
            id
          }
        }
      }
      allForpanelbeaters {
        edges {
          node {
            id
            url
            text
          }
        }
      }
      allNeedhelp {
        edges {
          node {
            id
            url
            text
          }
        }
      }
    }
  `);

  const { general } = data;
  const aboutus = data.allAboutus.edges;
  const forpanelbeaters = data.allForpanelbeaters.edges;
  const needhelp = data.allNeedhelp.edges;

  const returnFooterItem = (url, text) => {
    if (url !== null) {
      return <a href={url} className="footer-menu-link">{text}</a>;
    }

    return <span className="footer-menu-link">{text}</span>
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col footer-col">
            <h3 className="footer-menu-title">About us</h3>
            <ul className="footer-menu">
              {aboutus.length > 0 && (
                aboutus.map(({ node }) => (
                  <li key={node.id}>
                    {returnFooterItem(node.url, node.text)}
                  </li>
                )))}
            </ul>
          </div>
          <div className="col footer-col">
            <h3 className="footer-menu-title">For Panel Beater</h3>
            <ul className="footer-menu">
              {forpanelbeaters.length > 0 && (
                forpanelbeaters.map(({ node }) => (
                  <li key={node.id}>
                    {returnFooterItem(node.url, node.text)}
                  </li>
                )))}
            </ul>
          </div>
          <div className="col footer-col">
            <h3 className="footer-menu-title">Need Help?</h3>
            <ul className="footer-menu">
              {needhelp.length > 0 && (
                needhelp.map(({ node }) => (
                  <li key={node.id}>
                    {returnFooterItem(node.url, node.text)}
                  </li>
                )))}
            </ul>
          </div>
          <div className="col footer-col">
            <h3 className="footer-menu-title">Follow Us</h3>
            <ul className="footer-menu-social">
              <li>
                <a href={general.facebook} target="_blank" rel="noopener" aria-label="Facebook icon"><i className="icon-facebook-square"></i></a>
              </li>
              <li>
                <a href={general.linkedin} target="_blank" rel="noopener" aria-label="LinkedIn icon"><i className="icon-linkedin-square"></i></a>
              </li>
              <li>
                <a href={general.instagram} target="_blank" rel="noopener" aria-label="Instagram icon"><i className="icon-instagram"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="footer-copyright">
              <ul>
                <li>
                  <span>Copyright DingGo 2024</span>
                </li>
                <li>
				  <Link to="/sitemap">Sitemap</Link>
                </li>
                <li>
                  <Link to="/privacypolicy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/termsandconditions">Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
