import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { organiseLocations } from './helper';
import ArrowLeft from '../images/icon-arrow-left.inline.svg';
import Logo from '../images/dinggo-logo.inline.svg';
import CMLogo from '../images/Dinggo-CM-Logo-Grey.inline.svg';
import '../assets/styles/components/header.scss';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      allServices {
        edges {
          node {
            slug
            text
            url
            id
          }
        }
      }
      allCarmakes {
        edges {
          node {
            slug
            text
            id
            url
          }
        }
      }
      allSuburbList {
        edges {
          node {
            url
            text
            state
            slug
          }
        }
      }
      allStateList {
        edges {
          node {
            id
            url
            text
          }
        }
      }
      general {
        email
        phone
        country_code
      }
    }
  `);
  const services = data.allServices.edges;
  const carMakes = data.allCarmakes.edges;
  const { allSuburbList, allStateList, general } = data;
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showAccordion, setShowAccordion] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    setStates(organiseLocations(allSuburbList.edges, allStateList.edges));
  }, [allStateList]);

  const accordion = [];
  const subMenu = [];
  const height = [];

  const toggleAccordion = (idx, e) => {
    const parent = e.target.parentNode.parentNode;
    let accordionBody = parent.getElementsByClassName('accordion-body-inner')[0].offsetHeight;
    let toggle = true;
    if (typeof showAccordion.accordion !== 'undefined') {
      toggle = !showAccordion.accordion[idx];
    }

    accordion[idx] = toggle;
    height[idx] = accordionBody;

    setShowAccordion({
      accordion,
      height
    });
  };

  const toggleSubMenu = (idx) => {
    let toggle = true;
    if (typeof showSubMenu.subMenu !== 'undefined') {
      toggle = !showSubMenu.subMenu[idx];
    }

    subMenu[idx] = toggle;

    setShowSubMenu({
      subMenu
    });
  };

  const addSubMenuClass = (idx) => {
    if (showSubMenu.subMenu) {
      return showSubMenu.subMenu[idx]
    }

    return false;
  };

  const addAccordionClass = (idx) => {
    if (showAccordion.accordion) {
      return showAccordion.accordion[idx]
    }

    return false;
  };

  const getInnerHeight = (idx) => {
    if (showAccordion.height) {
      return showAccordion.height[idx]
    }

    return 0;
  };

  return (
    <>
      <header className={`header ${showMenu ? 'header-open' : ''}`}>
        {data.general.country_code === 'au' && (
          <Link to="/" className="logo-wrapper">
            <Logo alt="DingGo Logo" />
          </Link>
        )}
        {data.general.country_code === 'nz' && (
          <Link to="/" className="logo-wrapper logo-wrapper-nz">
            <CMLogo alt="Crash Management Logo" />
          </Link>
        )}
        <ul className="main-menu">
          {data.general.country_code === 'au' && (
            <li className="nav-list">
              <Link to="/crash-management" className="navigation-link" activeClassName="active">
                Crash Management
              </Link>
            </li>
          )}
          <li className="nav-list">
            <a href="/panel-beating-services" className="navigation-link">Services</a>
            <button
              className={`icon-chevron-down ${addSubMenuClass('services') ? 'icon-chevron-down-open' : ''}`}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                toggleSubMenu('services');
              }}/>
            <div className={`sub-menu ${addSubMenuClass('services') ? 'sub-menu-open' : ''}`}>
              <ul className="container">
                {services.length > 0 && (
                  services.map(({node}) => (
                    <li key={node.id}>
                      <a href={node.url}>{node.text}</a>
                    </li>
                  )))}
              </ul>
            </div>
          </li>
          <li className="nav-list need-help">
            <a href="/about" className="navigation-link">Our Company</a>
            <button
              className={`icon-chevron-down ${addSubMenuClass('ourcompany') ? 'icon-chevron-down-open' : ''}`}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                toggleSubMenu('ourcompany');
              }}/>
            <div className={`sub-menu ${addSubMenuClass('ourcompany') ? 'sub-menu-open' : ''}`}>
              <ul className="container dropdown-menu">
                <li className="dd-text text-lg"><a className="grey-link text-lb noPad" href="/about">About</a></li>
                <li className="dd-text text-lg"><a className="grey-link text-lb noPad" href="/contactus">Contact Us</a>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-list">
            <Link to="/login" className="navigation-link" activeClassName="active">Login</Link>
          </li>
          <li className="btn-wrapper">
            <Link to="/demo" className="btn" activeClassName="active">Request a Demo</Link>
          </li>
        </ul>
        <div className="hamburger-wrapper">
          <button
            type="button"
            className="hamburger"
            aria-label="hamburger menu"
            onClick={(e) => {
              e.preventDefault();
              setShowMenu(!showMenu);
            }}
          >
            <span className="hamburger-box">
              <span className={`hamburger-inner ${showMenu ? 'hamburger-inner-open' : ''}`} />
            </span>
          </button>
        </div>
      </header>
    </>
  );
};

export default Header;
